// pages with rectangular images

// please add handles in alphabetial order
const RECTANGULAR_DISPLAY_PAGES = [
  'a-line',
  'ball-gown',
  'bridal-dresses-gowns',
  'bridal-bras-bralettes',
  'bridal-separates',
  'bridal-slips-shapewear',
  'bridesmaids-bridal-party',
  'bridesmaid-dress',
  'guest-attire',
  'groom-groomsmen',
  'intimate-bras-bralettes',
  'jumpsuits',
  'lounge-wear',
  'lingerie-sets',
  'little-white-dresses',
  'mermaid',
  'pj-sets',
  'robes-kimonos',
  'robes-pj-sets-loungewear',
  'second-looks',
  'separates',
  'slips-shapewear',
  'tops-camisoles',
  'sheath',
  'suits',
  'tuxes',
  'wedding-party-attire',
  'womens-suits',
  'mother-of-the-bride',
] as const;

export const isRectangularDisplayPage = (
  categoryKey: string
): categoryKey is (typeof RECTANGULAR_DISPLAY_PAGES)[number] =>
  (RECTANGULAR_DISPLAY_PAGES as readonly string[]).indexOf(categoryKey) > -1;

// please add handles in alphabetial order
const RECTANGULAR_BRAND_PAGES = [
  'adelyn-rae',
  'birdy-grey',
  'black-halo',
  'cocomelody',
  'eberjey',
  'fame-and-partners',
  'khloe-jaymes',
  'kleinfeld-bridal-party',
  'lace-liberty',
  'lara',
  'likely',
  'mac-duggal',
  'morgan-lane',
  'snapsuits',
  'the-groomsman-suit',
  'zola-bridal',
  'bardot',
  'plum-pretty-sugar',
  'pantora-bridal',
  'dress-the-population',
  'kiyonna',
  'bariano',
] as const;

// payload related constants
const DEFAULT_PAYLOAD = {
  offset: 0,
  limit: 24,
  facets_to_return: ['FRONT_TAX', 'PRICE_RANGE', 'SALE', 'COLOR', 'TAXONOMY_FACET', 'BRAND'],
  sorts: [],
} as const;

const BRANDS_PAYLOAD = {
  ...DEFAULT_PAYLOAD,
  facets_to_return: ['FRONT_TAX', 'PRICE_RANGE', 'SALE', 'COLOR', 'PRODUCT_LINE'],
} as const;

const EXPERIENCE_PAYLOAD = {
  ...DEFAULT_PAYLOAD,
  facets_to_return: ['FRONT_TAX', 'TAG', 'PRICE_RANGE', 'SALE', 'BRAND'],
} as const;

// 'sort by' related constants
const DEFAULT_SORT_OPTION = 'FEATURED' as const;

type SortOption = {
  value: string;
  title: string;
};
type SortOptionV3 = {
  value: string;
  label: string;
};

const SORT_OPTIONS: SortOption[] = [
  {
    value: DEFAULT_SORT_OPTION,
    title: 'Featured',
  },
  {
    value: 'PRICE_ASCENDING',
    title: 'Price: Low to High',
  },
  {
    value: 'PRICE_DESCENDING',
    title: 'Price: High to Low',
  },
  {
    value: 'DATE_ADDED_DESCENDING',
    title: 'Newest',
  },
  {
    value: 'ALPHABETICAL_ASCENDING',
    title: 'A - Z',
  },
];

const SORT_OPTIONS_V3: SortOptionV3[] = [
  {
    value: DEFAULT_SORT_OPTION,
    label: 'Featured',
  },
  {
    value: 'PRICE_ASCENDING',
    label: 'Price: Low to High',
  },
  {
    value: 'PRICE_DESCENDING',
    label: 'Price: High to Low',
  },
  {
    value: 'DATE_ADDED_DESCENDING',
    label: 'Newest',
  },
  {
    value: 'ALPHABETICAL_ASCENDING',
    label: 'A - Z',
  },
];

// filters and url query
const FACETS_PAYLOAD_KEY_MAP = {
  PRICE_RANGE: 'price_ranges',
  SALE: 'on_sale',
  COLOR: 'colors',
  BRAND: 'brand_ids',
  PRODUCT_LINE: 'product_line_ids',
  TAXONOMY_FACET: 'taxonomy_facet_option_groups',
  TAG: 'tags',
} as const;

const TAXONOMY_FACET_QUERY_KEY = 'txfacet' as const;

const EXPERIENCES_CATEGORY = 'experiences' as const;

// TODO: think of a better name
const TAXONOMY_FACET = 'TAXONOMY_FACET' as const;

// parameters from url mapped to corresponding state parameters
const QUERY_MAP_TO_STATE = {
  spi: 'selectedProductIndex',
  sort: 'selectedSortValue',
  fti: 'frontTaxId',

  price: 'PRICE_RANGE',
  on_sale: 'SALE',
  tags: 'TAG',
  color: 'COLOR',
  brands: 'BRAND',
  prodline: 'PRODUCT_LINE',
  [TAXONOMY_FACET_QUERY_KEY]: 'TAXONOMY_FACET',
} as const;

// state parameters mapped to url query paramter names
const STATE_MAP_TO_QUERY = Object.keys(QUERY_MAP_TO_STATE).reduce(
  (obj, key) =>
    Object.assign(obj, { [QUERY_MAP_TO_STATE[key as keyof typeof QUERY_MAP_TO_STATE]]: key }),
  {}
);

const MEDIA_SCREEN_SIZES = {
  DESKTOP_MIN: '992px',
  MOBILE_MAX: '991px',
  TABLET_MIN: '768px',
} as const;

const FILTER_POSITION_SETTINGS = {
  top: 104,
  bottom: 50,
};

const MARKETING_TAG_PREFIX = 'shop_category_' as const;
const TEST_MODULE_GROUP_QUERY_PARAM = 'moduleGroupTargetingTag' as const; // used for preview module groups
const MODULE_POSITIONS_ON_PLP = [10, 22, 42] as const; // currently these are static positions applied to all marketing modules

const SITE_NAME = (isBaby = false) => (isBaby ? 'Zola Baby' : 'Zola');
const SITE_TITLE = (isBaby = false) => ` | ${SITE_NAME(isBaby)}`;

const DEFAULT_TITLE = (isBaby = false) =>
  isBaby ? 'The home of all things baby' : 'The home of all things wedding';
const DEFAULT_DESCRIPTION = (isBaby = false) =>
  isBaby
    ? 'Get ready for baby with essentials from all your favorite brands.  Add these great gifts to your Zola Baby Registry'
    : 'Give your space a major upgrade with home essentials from all your favorite brands. Add these great gifts to your Zola Wedding Registry.';

const SHOP_PAGE_DEFAULT_OG_IMAGE = (isBaby = false) =>
  isBaby
    ? 'https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/logos/ZOLABABY_ICON-L_BLUE.png'
    : 'https://d1tntvpcrzvon2.cloudfront.net/static-assets/images/logos/zola-logomark-marine.png';

/** The new arrivals collection key differs by context */
const NEW_ARRIVAL_COLLECTIONS = ['wedding-shop-new-arrivals', 'new-arrivals', 'baby-new-arrivals'];

export const isNewArrivalCollection = (collectionKey: string) =>
  NEW_ARRIVAL_COLLECTIONS.includes(collectionKey);

export const getNewArrivalCollectionKey = ({
  isBaby,
  isBoutique,
}: {
  isBaby?: boolean;
  isBoutique?: boolean;
} = {}) => {
  if (isBaby) {
    return 'baby-new-arrivals';
  }

  if (isBoutique) {
    return 'wedding-shop-new-arrivals';
  }

  return 'new-arrivals';
};

export {
  DEFAULT_PAYLOAD,
  MEDIA_SCREEN_SIZES,
  BRANDS_PAYLOAD,
  FACETS_PAYLOAD_KEY_MAP,
  SORT_OPTIONS,
  SORT_OPTIONS_V3,
  DEFAULT_SORT_OPTION,
  TAXONOMY_FACET_QUERY_KEY,
  TAXONOMY_FACET,
  QUERY_MAP_TO_STATE,
  STATE_MAP_TO_QUERY,
  EXPERIENCES_CATEGORY,
  EXPERIENCE_PAYLOAD,
  RECTANGULAR_DISPLAY_PAGES,
  RECTANGULAR_BRAND_PAGES,
  FILTER_POSITION_SETTINGS,
  MARKETING_TAG_PREFIX,
  TEST_MODULE_GROUP_QUERY_PARAM,
  MODULE_POSITIONS_ON_PLP,
  DEFAULT_TITLE,
  SITE_TITLE,
  SITE_NAME,
  DEFAULT_DESCRIPTION,
  SHOP_PAGE_DEFAULT_OG_IMAGE,
  NEW_ARRIVAL_COLLECTIONS,
};
