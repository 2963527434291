import type {
  WAddSkuRequest,
  WProductView,
  WRegistryCollectionItemView,
} from '@zola/svc-web-api-ts-client';
import ApiService from '@zola-helpers/client/dist/es/http/api';

export const isInstantRegistryEligible = () => {
  return ApiService.get<boolean>('/web-registry-api/v1/instant-registry/eligible');
};

export const getInstantRegistryProducts = () => {
  return ApiService.get<WProductView[]>('/web-registry-api/v1/instant-registry/products');
};

export const addInstantRegistryProducts = (skuIds: string[], products: WProductView[]) => {
  const body: WAddSkuRequest[] = skuIds.map((skuId) => {
    let quantity = 1;
    products.some((product) => {
      return product.product_look_views?.some((productLook) => {
        return productLook.sku_previews?.some((skuPreview) => {
          if (skuPreview.id === skuId) {
            quantity = product.suggested_quantity || 1;
            return true;
          }
          return false;
        });
      });
    });
    return {
      sku_id: skuId,
      quantity,
    };
  });
  return ApiService.post<WRegistryCollectionItemView[]>(
    '/web-registry-api/v1/instant-registry/add',
    body
  );
};
