import type { WPriceRangeView, WReferencePriceRange } from '@zola/svc-web-api-ts-client';

export function convertCurrencyFormat(
  amount: number | undefined,
  currency = 'USD',
  minimumFractionDigits = 0,
  maximumFractionDigits = 0
): string | undefined {
  return amount?.toLocaleString('en', {
    style: 'currency',
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
  });
}

/**
 * Formats a price as USD without decimals if integer, and with 2 decimals otherwise.
 *
 * @param {number} [priceCents] - The price to format
 * @param {boolean} [forSegment] - When true, commas are removed from the formatted price
 */
export const formatPrice = (priceCents: number | undefined, forSegment?: boolean) => {
  if (!priceCents) return null;
  // hide decimals if cents are zero
  const minPriceFractionDigits = (priceCents / 100) % 1 > 0 ? 2 : 0;
  const formattedPrice = convertCurrencyFormat(
    priceCents / 100,
    'USD',
    minPriceFractionDigits,
    2
  ) as string;
  if (forSegment) {
    // slice off the dollar sign and convert for tracking
    return Number(formattedPrice.replaceAll(',', '').slice(1));
  }
  return formattedPrice;
};

/**
 * Formats a price range as "$X - $Y" if both values are different, otherwise returning a single formatted price.
 *
 * @param {Object} [priceRange] - The price range to format
 * @param {number} [priceRange.min_price] - The price range's min price in cents
 * @param {number} [priceRange.max_price] - The price range's max price in cents
 */
export const formatZolaPriceRange = (
  priceRange?: { min_price: number; max_price: number } | WPriceRangeView | WReferencePriceRange
) => {
  if (!priceRange) return '';

  if (priceRange.min_price === priceRange.max_price) {
    const singlePrice = formatPrice(priceRange.min_price);
    return `${singlePrice}`;
  }
  const minPrice = formatPrice(priceRange.min_price);
  const maxPrice = formatPrice(priceRange.max_price);
  return `${minPrice} - ${maxPrice}`;
};

export const formatStringToNumber = (value?: unknown): number | undefined => {
  let parsed = Number.NaN;

  if (typeof value === 'string') {
    parsed = Number(value.replace(/,/g, ''));
  } else if (typeof value === 'number') {
    parsed = Number(value);
  }

  if (Number.isNaN(parsed)) return undefined;
  return parsed;
};

export const formatNumberWithCommas = (value: any) => {
  const parsed = formatStringToNumber(value);

  if (typeof parsed !== 'number' || Number.isNaN(parsed)) return '';

  return parsed.toLocaleString('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: Math.floor(parsed) === parsed ? 0 : 2,
  });
};

/** Removes commas from a number */
export const normalizeNumberWithCommas = (value: string) => {
  const parsed = formatStringToNumber(value);
  return parsed ? `${parsed}` : '';
};
