import fetch from 'isomorphic-fetch';
import _includes from 'lodash/includes';
import _pickBy from 'lodash/pickBy';

const Method = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
};

const handleErrors = ([ok, response]) => {
  if (!ok) {
    const errorMessage = (response.error && response.error.message) || 'An error has occurred';
    const error = new Error(errorMessage);
    error.response = response;
    throw error;
  }
  return response;
};

const handlePromise = (response) => {
  const contentType = response.headers && response.headers.get('Content-Type');
  if (_includes(contentType, 'application/json')) {
    return Promise.all([response.ok, response.json()]);
  }
  return Promise.all([response.ok, response.text()]);
};

const request = (url, options) => fetch(url, options).then(handlePromise).then(handleErrors);

const get = (url, options = {}, headers = {}) => {
  const finalHeaders = { 'Content-Type': 'application/json', ...headers };
  const finalOptions = {
    method: Method.get,
    credentials: 'same-origin',
    headers: finalHeaders,
    ...options,
  };

  return request(url, finalOptions);
};

const post = (url, body = {}, options = {}, headers = {}) => {
  const combinedHeaders = { 'Content-Type': 'application/json', ...headers };
  const finalHeaders = _pickBy(combinedHeaders, (v) => v !== undefined);
  const finalBody =
    finalHeaders['Content-Type'] === 'application/json' ? JSON.stringify(body) : body;
  const finalOptions = {
    method: Method.post,
    credentials: 'same-origin',
    body: finalBody,
    headers: finalHeaders,
    ...options,
  };

  return request(url, finalOptions);
};

const deleteMethod = (url, options = {}, headers = {}) => {
  const finalHeaders = { 'Content-Type': 'application/json', ...headers };
  const finalOptions = {
    method: Method.delete,
    credentials: 'same-origin',
    headers: finalHeaders,
    ...options,
  };

  return request(url, finalOptions);
};

const put = (url, body = {}, options = {}, headers = {}) => {
  const combinedHeaders = { 'Content-Type': 'application/json', ...headers };
  const finalHeaders = _pickBy(combinedHeaders, (v) => v !== undefined);
  const finalBody =
    finalHeaders['Content-Type'] === 'application/json' ? JSON.stringify(body) : body;
  const finalOptions = {
    method: Method.put,
    credentials: 'same-origin',
    body: finalBody,
    headers: finalHeaders,
    ...options,
  };

  return request(url, finalOptions);
};

const ApiService = {
  request,
  get,
  put,
  post,
  delete: deleteMethod,
};

export default ApiService;
