import { useCallback } from 'react';

import { trackInstantRegistryItemsAdded } from '@zola/zola-ui/src/components/InstantRegistry/helpers';

import dynamic from 'next/dynamic';

import { addInstantRegistryProducts as doAddInstantRegistryProducts } from '~/lib/client/api/instantRegistry';

import { AddInstantRegistryProductsFn } from './ManageRegistryCollectionContext';

const BabyManageRegistryCollectionProvider = dynamic(
  () => import('./ManageBabyRegistryCollectionProvider'),
  {
    loading: () => <></>,
  }
);

const WeddingManageRegistryCollectionProvider = dynamic(
  () => import('./ManageWeddingRegistryCollectionProvider'),
  {
    loading: () => <></>,
  }
);

export const ManageRegistryCollectionProvider: React.FC<{
  registryObjectId: string | null;
  isBaby: boolean;
}> = ({ isBaby, registryObjectId, ...rest }) => {
  const addInstantRegistryProducts: AddInstantRegistryProductsFn = useCallback(
    (section, instantRegistryProducts, skuIds) => {
      trackInstantRegistryItemsAdded(
        section,
        instantRegistryProducts,
        skuIds,
        registryObjectId ?? undefined
      );
      return doAddInstantRegistryProducts(skuIds, instantRegistryProducts);
    },
    [registryObjectId]
  );

  return isBaby ? (
    <BabyManageRegistryCollectionProvider
      addInstantRegistryProducts={addInstantRegistryProducts}
      registryObjectId={registryObjectId}
      {...rest}
    />
  ) : (
    <WeddingManageRegistryCollectionProvider
      addInstantRegistryProducts={addInstantRegistryProducts}
      registryObjectId={registryObjectId}
      {...rest}
    />
  );
};
