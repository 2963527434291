/* eslint-disable no-restricted-syntax */
// TODO: this FE logger should be moved to zola-helpers for reuse across zola

const assignLogLevel = (priority, name) => ({
  priority,
  name,
});

const LoggerConstants = {
  TRACE: assignLogLevel(1, 'TRACE'),
  DEBUG: assignLogLevel(2, 'DEBUG'),
  INFO: assignLogLevel(3, 'INFO'),
  WARN: assignLogLevel(5, 'WARN'),
  ERROR: assignLogLevel(8, 'ERROR'),
};

const handleOutput = (level, args) => {
  if (!console) {
    return;
  }

  const [message, ...context] = args;

  if (level === LoggerConstants.ERROR) {
    if (context && context[0] instanceof Error) {
      console.error(message, context[0].stack);
      if (typeof window !== 'undefined' && window.LogRocket) {
        window.LogRocket.captureException(context[0]);
      }
    } else {
      console.error(message, ...context);
      if (typeof window !== 'undefined' && window.LogRocket) {
        window.LogRocket.captureMessage(message);
      }
    }
    return;
  }

  if (level === LoggerConstants.WARN && console.warn) {
    console.warn(message, ...context);
  } else if (level === LoggerConstants.ERROR && console.error) {
    console.error(message, ...context);
  } else if (level === LoggerConstants.INFO && console.info) {
    console.info(message, ...context);
  } else if (level === LoggerConstants.DEBUG && console.debug) {
    console.debug(message, ...context);
  } else if (level === LoggerConstants.TRACE && console.trace) {
    console.trace(message, ...context);
  } else {
    console.debug(message, ...context);
  }
};

const trace = (...args) => handleOutput(LoggerConstants.TRACE, args);
const debug = (...args) => handleOutput(LoggerConstants.DEBUG, args);
const info = (...args) => handleOutput(LoggerConstants.INFO, args);
const warn = (...args) => handleOutput(LoggerConstants.WARN, args);
const error = (...args) => handleOutput(LoggerConstants.ERROR, args);

const Logger = {
  debug,
  info,
  warn,
  error,
  trace,
};

export default Logger;
