import { AppDownloadBannerMobile } from '@zola/zola-ui/src/components/AppDownloadBannerMobile/AppDownloadBannerMobile';
import BabyFooter from '@zola/zola-ui/src/components/footers/baby/BabyFooter/BabyFooter';
import { PreauthFooterV3 } from '@zola/zola-ui/src/components/PreAuthV3';
import { useEffectOnce } from '@zola/zola-ui/src/hooks/useEffectOnce';
import { useLocationForVendorSRPLink } from '@zola/zola-ui/src/hooks/useLocationForVendorSRPLink/useLocationForVendorSRPLink';
import { UserContext } from '@zola-helpers/client/dist/es/@types';
import {
  PrimaryNavIdentifier,
  SecondaryNavIdentifier,
} from '@zola-helpers/client/dist/es/constants/navConstants';

import cx from 'classnames';
import { connect } from 'react-redux';
import { StickyContainer } from 'react-sticky';

import { getShouldSkipMarketplaceLP } from '~/components/util/skipMarketplaceLPHelpers';
import { AppDispatch, RootState } from '~/store';

import * as NavActions from '../../../../actions/NavActions';
import UnifiedNavV2, { NAV_DATA } from '../../header/UnifiedNavV2';

import '@zola/zola-ui/src/components/Footer/Footer.less';
import './MainLayout.less';

type MainLayoutProps = {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  getCategories: () => void;
  categoriesLoaded: boolean;
  appDownloadBannerCampaignName?: string;
  activePrimaryLink?: PrimaryNavIdentifier;
  activeSecondaryLink?: SecondaryNavIdentifier;
  activeTertiaryLink?: SecondaryNavIdentifier;
  disablePrimaryNavCollapse?: boolean;
  disableSecondary?: boolean;
  isBaby?: boolean;
  isWeddingBoutiquePage?: boolean;
  showAppDownloadBanner?: boolean;
  userContext?: UserContext | null;
  user: {
    isSpoof?: boolean;
    firstName?: string;
    lastName?: string;
  };
  useMinHeight?: boolean;
};

const MainLayoutFooter = ({
  userContext,
  isBaby = false,
}: {
  userContext: UserContext | null;
  isBaby?: boolean;
}) => {
  const isGuest = userContext ? Boolean(userContext.is_guest) : true;
  const shouldSkipMarketplaceLP = getShouldSkipMarketplaceLP(userContext);

  const weddingLocation = useLocationForVendorSRPLink({
    shouldCheckLocation: shouldSkipMarketplaceLP || false,
  });

  if (isBaby) return <BabyFooter />;

  return (
    <div className={cx({ isPostAuthNav3Shown: !isGuest })}>
      <PreauthFooterV3 weddingLocation={weddingLocation} isGuest={isGuest} />
    </div>
  );
};
const MainLayout = (props: MainLayoutProps) => {
  const {
    id = '',
    children = null,
    className,
    appDownloadBannerCampaignName,
    activePrimaryLink = NAV_DATA.PRIMARY.REGISTRY,
    activeSecondaryLink,
    activeTertiaryLink,
    disablePrimaryNavCollapse,
    disableSecondary = false,
    isBaby,
    isWeddingBoutiquePage,
    showAppDownloadBanner,
    userContext,
    user = {
      isSpoof: false,
      firstName: '',
      lastName: '',
    },
    useMinHeight = false,
    categoriesLoaded,
    getCategories,
  } = props;

  useEffectOnce(() => {
    if (!categoriesLoaded) {
      getCategories();
    }
  });

  const isGuest = userContext ? Boolean(userContext.is_guest) : true;
  const isPostAuthNav3Shown = !isGuest && !isBaby;

  return (
    <div className={cx({ postAuthNav3PageBackground: isPostAuthNav3Shown }, className)}>
      <div
        className={cx({
          postAuthNav3PageWrapper: isPostAuthNav3Shown,
          pageMinHeight: useMinHeight,
        })}
      >
        {showAppDownloadBanner ? (
          <AppDownloadBannerMobile campaignName={appDownloadBannerCampaignName} isBaby={isBaby} />
        ) : null}
        <StickyContainer id={id} className="unSticky">
          <UnifiedNavV2
            activePrimaryLink={activePrimaryLink}
            activeSecondaryLink={activeSecondaryLink}
            activeTertiaryLink={activeTertiaryLink}
            disablePrimaryNavCollapse={disablePrimaryNavCollapse}
            disableSecondary={disableSecondary}
            isBaby={isBaby}
            isWeddingBoutiquePage={isWeddingBoutiquePage}
            showPromo
          />
          {user.isSpoof && (
            <div className="spoof-header">
              <h1>
                Logged in as {user.firstName} {user.lastName}
              </h1>
            </div>
          )}
          <div className={cx({ isPostAuthNav3Shown })}>{children}</div>
        </StickyContainer>
        <MainLayoutFooter isBaby={isBaby} userContext={userContext ?? null} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { userContext } = state.user;

  return {
    categoriesLoaded: state.nav.categoriesLoaded,
    user: {
      isSpoof: userContext?.is_spoof,
      firstName: userContext?.first_name,
      lastName: userContext?.last_name,
    },
    userContext,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCategories: () => dispatch(NavActions.getCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
