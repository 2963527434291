import { UserContext } from '~/actions/UserActions.type';
import { ThemeSelector } from '~/components/shop/ThemeSelector/ThemeSelector';

import { RegistryShoppingProvider } from './RegistryContext/RegistryShoppingProvider';

export const RegistryShoppingPage: React.FC<{
  userContext: UserContext | null;
}> = ({ userContext, children }) => {
  return (
    <>
      <ThemeSelector>
        <RegistryShoppingProvider userContext={userContext}>{children}</RegistryShoppingProvider>
      </ThemeSelector>
    </>
  );
};
